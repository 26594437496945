import React, { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useBackendUserState } from 'contexts/UserContext';
import { useHistory, useParams } from 'react-router';
import useWizardDecisionAndRedirect from 'apps/wizard/hooks/useWizardDecisionAndRedirect';
import { ME, VERIFY_EMAIL } from 'shared/queries/account/queries';
import { Alert } from '@material-ui/lab';
import isEmpty from 'lodash/isEmpty';
import paths from 'paths';

const VerifyEmail = () => {
  const { hashid } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const { data: { verificationHash = {} } = {}, error } = useQuery(VERIFY_EMAIL, { variables: { linkHash: hashid } });
  const { state: { user: backendUser, called } } = useBackendUserState();
  const history = useHistory();
  const client = useApolloClient();

  const { wizardLink } = useWizardDecisionAndRedirect();
  const wizardCompleted = backendUser?.wizardState?.isWizardCompleted;

  useEffect(() => {
    if (called) {
      if (!isEmpty(verificationHash) || error) {
        const path = wizardCompleted ? paths.rotationPaths.all.reverse() : wizardLink;

        if (verificationHash?.emailVerified === true) {
          history.push({
            pathname: path,
            state: { verificationStatus: 'VERIFIED' },
          });
          client.refetchQueries({
            include: [ME],
          });
        } else if (error) {
          setErrorMessage(error.message);
          setTimeout(() => {
            history.push({
              pathname: path,
              state: { verificationStatus: error.message },
            });
          }, 2000);
        } else {
          history.push(path);
        }
      }
    }
  }, [called, verificationHash, error, wizardCompleted, wizardLink, history, client]);

  return errorMessage ? (
    <Alert severity="error">
      {errorMessage}
    </Alert>
  ) : (
    <>
      Redirecting...
    </>
  );
};

export default VerifyEmail;
