import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Box, Typography, Button, Divider, Grid, Dialog, DialogContent, CircularProgress,
} from '@material-ui/core';
import { ROTATION_CONSTANTS } from 'shared/queries/rotation/queries';
import DialogTitle from 'shared/components/DialogTitle';
import RefundPolicyLink from 'shared/components/RefundPolicyLink';
import SectionTagDateHours from 'shared/components/RotationPaymentPreceptorsBlock/partials/SectionTagDateHours';
import PaymentBlock from 'apps/rotation/components/RotationReservationPaymentPreceptorInfoBlock/partials/PaymentBlock';
import AmountBlock from 'apps/finances/components/FinancesHistoryTable/partials/AmountBlock';
import paymentDescriptions from 'apps/finances/constants/paymentDescriptions';
import rotationCustomSearchReaquestStatuses from 'apps/rotation/constants/rotationCustomSearchReaquestStatuses';
import paths from 'paths';
import requestCustomSearchStyles from './styles';

const RequestCustomSearchDialog = ({
  startDate, endDate, hours, specialty, owners, rotationUID, rotationUuid, firstPaymentStatus,
  customSearchRequestStatus, ButtonComponent, buttonText,
}) => {
  const classes = requestCustomSearchStyles();
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    data: {
      rotationConstants: {
        customSearchPaymentOne = 150,
      } = {},
    } = {},
  } = useQuery(ROTATION_CONSTANTS);

  const error = '';
  const errorAdd = '';
  const setEmpty = () => {};
  const setError = () => {};
  const setErrorAdd = () => {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setProcessing(true);
  };

  useEffect(() => {
    if (
      ((firstPaymentStatus === 'failed')
        || (customSearchRequestStatus === rotationCustomSearchReaquestStatuses.PENDING_REQUEST && !firstPaymentStatus))
    ) {
      const lastOpenedDate = localStorage.getItem(`lastOpened_${rotationUID}`);
      const currentDate = new Date();
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);
      if (!lastOpenedDate || new Date(lastOpenedDate) < oneMonthAgo) {
        setOpen(true);
        localStorage.setItem(`lastOpened_${rotationUID}`, currentDate.toISOString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span>
      {ButtonComponent ? (
        <ButtonComponent onClick={handleClickOpen}>{buttonText}</ButtonComponent>
      ) : (
        <Button variant="contained" size="medium" onClick={handleClickOpen} disabled={customSearchRequestStatus}>
          Request Custom Search
        </Button>
      )}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <Box mb={2}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Request Custom Search
          </DialogTitle>
        </Box>
        <DialogContent>
          <Box mx={-4.5}>
            <Divider />
          </Box>
          <Box display="flex" alignItems="center" mt={3} mb={2}>
            <Box>
              <Typography className={classes.textSecondary}>
                Rotation ID&#58;
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text}>
                &thinsp;
                {' '}
                {rotationUID}
              </Typography>
            </Box>
          </Box>
          <SectionTagDateHours
            startDate={startDate}
            endDate={endDate}
            hours={hours}
            specialty={specialty}
            owners={owners}
            rotationUID={rotationUID}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Box>
              <AmountBlock amount={customSearchPaymentOne} />
            </Box>
            <Box mt={1.5}>
              <RefundPolicyLink />
            </Box>
          </Box>
          <PaymentBlock
            setProcessing={setProcessing}
            errorAdd={errorAdd}
            error={error}
            setEmpty={setEmpty}
            setErrorAdd={setErrorAdd}
            rotationUUID={rotationUuid}
            setError={setError}
            paymentDescription={paymentDescriptions.customSearchPaymentOne}
            setCloseDialog={handleClose}
            redirectTo={paths.rotationPaths.rotationAllPreceptors}
          />
          <Box my={3}>
            <Grid container direction="row" alignItems="center" justify="space-between" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button variant="outlined" size="large" onClick={handleClose} fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button variant="contained" size="large" type="submit" onClick={handleClick} form="pay_and_reserve" fullWidth>
                  {processing ? <CircularProgress size="9%" /> : 'Pay & Request'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
};

RequestCustomSearchDialog.defaultProps = {
  ButtonComponent: null,
  buttonText: '',
};

RequestCustomSearchDialog.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  hours: PropTypes.number.isRequired,
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })).isRequired,
  owners: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      getName: PropTypes.string,
      email: PropTypes.string,
    }),
  })).isRequired,
  rotationUID: PropTypes.string.isRequired,
  customSearchRequestStatus: PropTypes.string.isRequired,
  ButtonComponent: PropTypes.element,
  buttonText: PropTypes.string,
  firstPaymentStatus: PropTypes.string.isRequired,
  rotationUuid: PropTypes.string.isRequired,
};

export default RequestCustomSearchDialog;
