import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Menu, MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import rotationTagHiddenStyles from './styles';

const ITEM_HEIGHT = 48;

const RotationTagHidden = ({ specialty }) => {
  const classes = rotationTagHiddenStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        {specialty.slice(0, 2).map(item => (
          <Chip
            key={item.key}
            className={classes.chip}
            variant="outlined"
            size="small"
            label={item.value}
          />
        ))}

        <Box onClick={handleClick}>
          {specialty.length > 2 && (
            <Typography className={classes.tag}>
              +
              {specialty.length - 2}
            </Typography>
          )}
        </Box>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {specialty.slice(2).map(option => (
            <MenuItem key={option} onClick={handleClose}>
              {option.value}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

RotationTagHidden.defaultProps = {
  specialty: [],
};

RotationTagHidden.propTypes = {
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
};

export default RotationTagHidden;
