import { gql } from '@apollo/client/core';

export const MY_ROTATIONS = gql`
  query myRotations {
    myRotations {
      totalCount
      results {
        uid
        uuid
        payments {
          description {
            key
          }
          status {
            key
          }
        }
        status {
          key
          value
        }
        startDate
        endDate
        paperworkDueDate
        paperworkDueDays
        paperworkStatus {
          key
          value
        }
        hours
        whichRotationThisWillBeForStudent
        completedHours
        completedPercents
        honorarium
        cost
        preferences
        specialty {
          key
          value
        }
        currentRequest {
          uuid
        }
        owners {
          user {
            getName
            email
          }
        }
        student {
          uid
          uuid
          firstName
          lastName
          school {
            name
          }
          profile {
            avatar
          }
        }
        preceptor {
          uid
          uuid
          firstName
          lastName
          profile {
            avatar
            credentials {
              key
              value
             }
          }
          clinic {
            name
          }
        }
      }
    }
  }
`;

export const ROTATION = gql`
  query rotation($uuid: String!) {
    rotation(uuid: $uuid) {
      contentTypeId
      uuid
      uid
      paymentType {
        key
        value
      }
      paymentStatus {
        key
        value
      }
      honorarium
      cost
      deposit
      secondpart
      balance
      alreadyPaid
      hours
      whichRotationThisWillBeForStudent
      rotationNote
      completedHours
      completedPercents
      startDate
      endDate
      paperworkDueDate
      customSearchRequestStatus
      customSearchRequestDate
      discount
      extraCharges
      preferences
      ignoreLmf
      processingDeposit
      previousRequestsPaidAmount
      owners {
        user {
          getName
          email
        }
      }
      payments {
        description {
          key
        }
        status {
          key
        }
      }
      status {
        key
        value
      }
      paperworkStatus {
        key
        value
      }
      specialty {
        key
        value
      }
      schoolPaperworkNeeded {
        key
        value
      }
      currentRequest {
        uuid
      }
      preceptor {
        id
        uuid
        uid
        firstName
        lastName
        email
        profile {
          avatar
          alternatePersonToCommunicateWith
          businessPhone
          phoneShareableWithStudent
          emailShareableWithStudent
          timezone {
            key
            value
            options {
              key
              value
            }
          }
          gender {
            key
            value
          }
          credentials {
            key
            value
          }
          specialty {
            key
            value
          }
          whatIsTheDressCodeForStudents {
            key
            value
          }
          typeOfPractice {
            key
            value
          }
          preceptorIntro
          clinicBusinessHours
          limitationsOrRestrictionsForStudents
          vaccinationcovidRequirements {
            key
            value
          }
          whatWillTheStudentExperienceLookLikeAtYourSite
          specialtyNote
          ifYouDoWomensHealthWillYouPreceptAMaleStudentWhoNeedsWomensHealth
          ifYouSeePsychPatientsDoYouDo {
            key
            value
          }
          university
          linkToWebsite
          preceptorLicense {
            name
            url
          }
          certification {
            name
            url
          }
          resumecv2709 {
            name
            url
          }
          averageNumberOfPatientsPerDay4755 {
            key
            value
          }
          studentsPrecepted
          dayshoursYouWork
          checklist
          honorariumType {
            key
            value
          }
          amount
          minimumHonorarium
          flatFeeForAllHours
          apartmentUnitSuiteOrFloor
        }
        clinic {
          name
        }
        location {
          generalLocation
          description
          point {
            lat
            lng
          }
        }
        honorariumHourRanges {
          uuid
          start
          end
          amount
        }
        availSettings {
          weekdays
          days {
            weekday {
              key
              value
            }
            start {
              key
              value
            }
            end {
              key
              value
            }
            hours
          }
        }
      }
    }
  }
`;

export const ROTATION_CONSTANTS = gql`
  query rotationConstants {
    rotationConstants {
        plFee
        plFeeNoHonorarium
        serviceFee
        lastMinuteFee
        lastMinuteFeeDaysAmount
        customSearchPaymentOne
        customSearchPaymentTwo
    }
  }
`;

export const CONFLICTING_ROTATIONS = gql`
  query conflictingRotations($start: String!, $end: String!, $uuid: String) {
    conflictingRotations(start: $start, end: $end, uuid: $uuid) {
        totalCount
        results {
          uid
          uuid
          preferences
          currentRequest {
            uuid
          }
        }
    }
  }
`;

export const ALL_ROTATION_AGREEMENTS_QUERY = gql`
  query allRotationUploadedAgreements($rotationUuid: String!) {
    allRotationUploadedAgreements (rotationUuid: $rotationUuid){
      id
      size
      agreement
      url
    }
  }
`;

export const ROTATION_MUTATION = gql`
  mutation rotation($input: RotationMutationInput!) {
    rotation(input: $input) {
      rotation {
        uuid
        specialty {
          key
          value
        }
        startDate
        endDate
        paperworkDueDate
        hours
        customSearchRequestStatus
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const CANCEL_REQUEST = gql`
  mutation cancelRequest($uuid: String!, $reason: String, $comment: String) {
    cancelRequest(uuid: $uuid, reason: $reason, comment: $comment) {
      status
      message
    }
  }
`;

export const REJECT_REQUEST = gql`
  mutation rejectRequest($uuid: String!, $reason: String, $comment: String) {
    rejectRequest(uuid: $uuid, reason: $reason, comment: $comment) {
      status
      message
    }
  }
`;

export const ACCEPT_REQUEST = gql`
  mutation acceptRequest($uuid: String!) {
    acceptRequest(uuid: $uuid) {
      status
      message
    }
  }
`;

export const SIGN_PAPERWORK = gql`
  mutation signPaperwork($uuid: String!) {
    signPaperwork(uuid: $uuid) {
      status
      message
    }
  }
`;

export const ROTATION_SCHOOL_PAPERWORK_NEEDED_MUTATION = gql`
  mutation rotationSchoolPaperworkNeeded($uuid: String!, $schoolPaperworkNeeded: String!) {
    rotationSchoolPaperworkNeeded(uuid: $uuid, schoolPaperworkNeeded: $schoolPaperworkNeeded) {
      status
      message
    }
  }
`;

export const REJECT_SCHOOL_APPROVAL = gql`
  mutation rejectSchoolApproval($uuid: String!, $reason: String, $comment: String) {
    rejectSchoolApproval(uuid: $uuid, reason: $reason, comment: $comment) {
      status
      message
    }
  }
`;

export const ACCEPT_SCHOOL_APPROVAL = gql`
  mutation acceptSchoolApproval($uuid: String!) {
    acceptSchoolApproval(uuid: $uuid) {
      status
      message
    }
  }
`;

export const SIGN_AFF_PAPERWORK = gql`
  mutation signAffPaperwork($uuid: String!) {
    signAffPaperwork(uuid: $uuid) {
      status
      message
    }
  }
`;

export const ROTATION_START = gql`
  mutation rotationStart($uuid: String!, $actionCode: String) {
    rotationStart(uuid: $uuid, actionCode: $actionCode) {
      status
      message
    }
  }
`;

export const SEND_CANCEL_REQUEST_EMAIL = gql`
  mutation sendCancelRequestEmail($uuid: String!) {
    sendCancelRequestEmail(uuid: $uuid) {
      status
      message
    }
  }
`;

export const ROTATION_HRS = gql`
  mutation rotationHrs($uuid: String!, $hours: Int) {
    rotationHrs(uuid: $uuid, hours: $hours) {
      status
      message
    }
  }
`;

export const ROTATION_COMPLETION = gql`
  mutation rotationCompletion($uuid: String!) {
    rotationCompletion(uuid: $uuid) {
      status
      message
    }
  }
`;

export const ADD_TO_FAVORITES = gql`
  mutation addToFavorites($uuid: String!, $preceptorUuid: String!) {
    addToFavorites(uuid: $uuid, preceptorUuid: $preceptorUuid) {
      status
      message
    }
  }
`;

export const REMOVE_FROM_FAVORITES = gql`
  mutation removeFromFavorites($uuid: String!, $preceptorUuid: String!) {
    removeFromFavorites(uuid: $uuid, preceptorUuid: $preceptorUuid) {
      status
      message
    }
  }
`;

export const UPLOAD_AGREEMENT = gql`
  mutation uploadAgreement($file: Upload!, $rotationUuid: String!) {
    uploadAgreement(file: $file, rotationUuid: $rotationUuid) {
      submitted
    }
  }
`;

export const CHANGE_ROTATION_PREFERENCES_MUTATION = gql`
  mutation changeRotationPreferences($uuid: String!, $preferences: JSONString!) {
    changeRotationPreferences(uuid: $uuid, preferences: $preferences) {
      status
      message
    }
  }
`;

export const ACCEPT_PRECEPTOR_REPLACEMENT = gql`
  mutation acceptPreceptorReplacement($uuid: String!) {
    acceptPreceptorReplacement(uuid: $uuid) {
      status
      message
    }
  }
`;

export const REJECT_PRECEPTOR_REPLACEMENT = gql`
  mutation rejectPreceptorReplacement($uuid: String!) {
    rejectPreceptorReplacement(uuid: $uuid) {
      status
      message
    }
  }
`;
