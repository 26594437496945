import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { Box, Typography, Link } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PaymentIcon from '@material-ui/icons/Payment';
import { formatDateShort } from 'shared/helpers/dateHelpers';
import RotationTagHidden from 'shared/components/RotationTagHidden';
import { BCC_DEFAULT_EMAIL } from 'shared/constants/sharedConstants';
import theme from 'theme';
import sectionTagDateHoursStyles from './styles';

const SectionTagDateHours = ({
  startDate, endDate, paperworkDueDate, hours, paymentType, specialty, owners, rotationUID,
}) => {
  const classes = sectionTagDateHoursStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const date = `${formatDateShort(startDate)} - ${formatDateShort(endDate)}`;
  const mailtoSubject = rotationUID ? `subject=Rotation <${rotationUID}>` : '';

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'start' : 'center'}>
      <RotationTagHidden specialty={specialty} />
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
        <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2} my={isMobile ? 1 : 0}>
          <CalendarTodayIcon className={classes.icon} fontSize="small" />
          <Typography variant="subtitle2">
            {date}
          </Typography>
        </Box>
        {paperworkDueDate && (
          <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2} my={isMobile ? 1 : 0}>
            <CalendarTodayIcon className={classes.icon} fontSize="small" />
            <Typography variant="subtitle2">
              {formatDateShort(paperworkDueDate)}
            </Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2} my={isMobile ? 0 : 0}>
          <QueryBuilderIcon className={classes.icon} fontSize="small" />
          <Typography variant="subtitle2">
            {hours}
            {' '}
            hrs
          </Typography>
        </Box>
        {paymentType && (
          <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2} my={isMobile ? 1 : 0}>
            <PaymentIcon className={classes.icon} fontSize="small" />
            <Typography variant="subtitle2">
              {paymentType.value}
            </Typography>
          </Box>
        )}
        {owners.length > 0 && (
          <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2} my={isMobile ? 1 : 0}>
            <Link
              className={classes.link}
              href={`mailto:${owners.map(owner => owner.user.email).join(',')}?bcc=${BCC_DEFAULT_EMAIL}&${mailtoSubject}`}
              target="_blank"
              rel="noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <AccountCircleIcon className={classes.icon} fontSize="small" />
              <Typography className={classes.label}>
                Clinic Coordinators:&nbsp;
              </Typography>
              <Typography className={classes.value}>
                {owners.map(owner => owner.user.getName).join(', ')}
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

SectionTagDateHours.defaultProps = {
  paperworkDueDate: '',
  paymentType: null,
};

SectionTagDateHours.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  paperworkDueDate: PropTypes.string,
  hours: PropTypes.number.isRequired,
  paymentType: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })).isRequired,
  owners: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      getName: PropTypes.string,
      email: PropTypes.string,
    }),
  })).isRequired,
  rotationUID: PropTypes.string.isRequired,
};

export default SectionTagDateHours;
