import * as Sentry from '@sentry/react';
import React, {
  createContext, useReducer, useContext, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSigninCheck } from 'reactfire';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ME } from 'shared/queries/account/queries';
import LinearProgressCustom from 'shared/components/LinearProgressCustom';
import { Crisp } from 'crisp-sdk-web';
import { openSelectRole, openSignUp } from 'actions/authDialogActions';

const SET_USER = 'SET_USER';
const userData = { user: {}, called: false };
const UserContext = createContext({});

const shouldInitSentry = ['dev', 'prod'].includes(process.env.REACT_APP_TARGET_ENV);

if (shouldInitSentry) {
  Sentry.init({
    dsn: 'https://c826a16c8e036ecfdd0c7a2ed8645c3f@o4506189134888960.ingest.sentry.io/4506200737710080',
    replaysSessionSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [''],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
  });
}

const userStateReducer = (state, action) => {
  if (action.type === SET_USER) {
    return { ...state, ...action.payload };
  }
  return state;
};

/**
 * @param children
 * @returns {JSX.Element}
 *
 * Stores PreceptorLink user
 */
export const UserProvider = ({ children }) => {
  const reduxDispatch = useDispatch();
  const { status, data: signInCheckResult } = useSigninCheck();
  const [state, dispatch] = useReducer(userStateReducer, userData);
  const location = useLocation();
  const locationChangeRef = useRef(0);

  const { loading, data: { me } = {} } = useQuery(ME, {
    fetchPolicy: 'network-only', skip: !signInCheckResult?.signedIn,
  });

  useEffect(() => {
    Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID);
  }, []);

  useEffect(() => {
    if (me?.email) {
      Crisp.user.setEmail(me?.email, me?.crispHmac);
    }
    if (me?.getName) {
      Crisp.user.setNickname(me?.getName);
    }
  }, [me?.email, me?.crispHmac, me?.getName]);

  useEffect(() => {
    if (me?.hasRole === false) reduxDispatch(openSelectRole());
  }, [me, reduxDispatch]);

  useEffect(() => {
    dispatch({ type: SET_USER, payload: { user: me, called: status !== 'loading' && !loading } });
  }, [me, status, loading]);

  useEffect(() => {
    if (shouldInitSentry && signInCheckResult?.signedIn) {
      Sentry.setUser({
        email: signInCheckResult?.user?.email,
      });
    }
  }, [signInCheckResult?.signedIn, signInCheckResult?.user?.email]);

  useEffect(() => {
    if (!signInCheckResult?.signedIn && locationChangeRef.current === 2) {
      reduxDispatch(openSignUp());
      localStorage.setItem('signUpDialogTime', new Date().getTime().toString());
    }
    locationChangeRef.current += 1;
  }, [location, reduxDispatch, signInCheckResult?.signedIn]);

  useEffect(() => {
    const checkSignUpDialog = () => {
      const signUpDialogTime = localStorage.getItem('signUpDialogTime');
      const currentTime = new Date().getTime();
      // 10 minutes
      if (!signInCheckResult?.signedIn && (!signUpDialogTime || currentTime - signUpDialogTime > 600000)) {
        reduxDispatch(openSignUp());
        localStorage.setItem('signUpDialogTime', currentTime.toString());
      }
    };

    const intervalId = setInterval(checkSignUpDialog, 60000); // check every minute

    return () => clearInterval(intervalId); // cleanup on unmount
  }, [reduxDispatch, signInCheckResult?.signedIn]);

  if (status === 'loading') return <LinearProgressCustom />;

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBackendUserState = () => useContext(UserContext);
