import accountPaths from 'apps/account/paths';
import commonPaths from 'apps/common/paths';
import searchPublicPaths from 'apps/searchPublic/paths';
import wizardPaths from 'apps/wizard/paths';
import financesPaths from 'apps/finances/paths';
import rotationPaths from 'apps/rotation/paths';
import preceptorPaths from 'apps/preceptor/paths';
import settingsPaths from 'apps/settings/paths';
import studentProfilePaths from 'apps/studentProfile/paths';

export default {
  accountPaths,
  commonPaths,
  searchPublicPaths,
  rotationPaths,
  wizardPaths,
  financesPaths,
  studentProfilePaths,
  preceptorPaths,
  settingsPaths,
};
