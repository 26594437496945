import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const requestCustomSearchStyles = makeStyles(() => createStyles({
  text: {
    ...typography.subtitle3,
    maxWidth: '100%',
  },
}));

export default requestCustomSearchStyles;
