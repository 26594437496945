const white = '#FFFFFF';
const black = '#171717';
const palette = {
  black,
  white,
  colors: {
    lightGreen: 'rgba(70, 164, 128, 0.32)',
    lightCyan: 'rgba(37, 195, 205, 0.08)',
    lightBlue: 'rgba(37, 134, 205, 0.08)',
    powderyGreen: 'rgba(70, 164, 128, 0.08)',
    lightGreenStatus: 'rgba(70, 164, 128, 0.08)',
    lightBlueBorder: 'rgba(0, 146, 192, 0.16)',
    lightPrimaryBlue: 'rgba(0, 146, 192, 0.08)',
    lightOrange: 'rgba(217, 119, 52, 0.08)',
    lightIndigo: 'rgba(84, 95, 198, 0.08)',
    lightPurple: 'rgba(212, 33, 228, 0.32)',
    lightPurpleBackground: 'rgba(212, 33, 228, 0.08)',
    lightOrangeBorder: 'rgba(217, 119, 52, 0.32)',
    lightCyanBorder: 'rgba(33, 228, 228, 0.32)',
    lightCyanBorderPreceptor: 'rgba(37, 195, 205, 0.16)',
    lightYellowBorder: 'rgba(228, 185, 33, 0.32)',
    lightRed: 'rgba(217, 52, 52, 0.08)',
    lightRedStatus: 'rgba(246, 68, 68, 0.08)',
    borderTypePractice: 'rgba(0, 119, 156, 0.16)',
    cyan: '#12AAAA',
    red: '#E23333',
    blue: '#1996DC',
    indigo: '#03009C',
    purple: '#9C0083',
    orange: '#FF6200',
    violet: '#7763F4',
    lightViolet: 'rgba(94, 70, 164, 0.08)',
    yellow: '#FFA800',
    loginButton: '#18a374',
    orangeBadge: '#FFB800',
    blueLink: '#02bbd1',
    lightGreenHover: 'rgba(29, 186, 127, 1)',
    lightgrey: 'rgba(147, 170, 170, 1)',
    // custom color
    lightGreenBg: '#20B9940A',
  },
  backgrounds: {
    backgroundChat: 'rgba(245, 252, 253, 0.96)',
    backgroundChat2: 'rgba(235, 246, 250, 0.96)',
    backgroundFilters: '#F5FCFD',
    backgroundBanner: '#FBF1EA',
    backgroundSelectedDate: 'rgba(70, 164, 128, 1)',
    backgroundRangeSelectedDate: 'rgba(0, 116, 72, 1)',
    backgroundPopperOpen: 'rgba(0, 146, 192, 0.08)',
  },
  gradients: {
    blueGradient: 'linear-gradient(270deg, #00AAE0 0.09%, #00B8F2 100.09%)',
    greenGradient: 'linear-gradient(270deg, #43B859 0.09%, #00BBDB 100.09%)',
    cyanGradient: 'linear-gradient(180deg, #DDFDF5 0%, #A0E8FF 98.55%)',
    brandingMain: 'linear-gradient(89.86deg, #02BBD1 0%, #1FB978 95.83%, #20B994 100%)',
  },
  buttons: {
    blueMain: 'linear-gradient(93.43deg, #02BBD1 2.82%, #72CDD8 97.18%)',
    blueDark: 'linear-gradient(93.43deg, #00B8CE 2.82%, #57C1CD 97.18%)',
    blueLight: 'linear-gradient(93.43deg, #13D5EC 2.82%, #81D7E1 97.18%)',
  },
  primary: {
    contrastText: white,
    dark: '#00779C',
    main: '#0092C0',
    light: '#21AAE4',
  },
  secondary: {
    contrastText: white,
    dark: '#007448',
    main: '#46A480',
    light: '#1DBA7F',
  },
  success: {
    contrastText: white,
    dark: '#007448',
    main: '#46A480',
    light: '#1DBA7F',
  },
  info: {
    contrastText: white,
    dark: '#00779C',
    main: '#0092C0',
    light: '#21AAE4',
  },
  warning: {
    contrastText: white,
    dark: '#A1673F',
    main: '#D97734',
    light: '#F18C46',
  },
  error: {
    contrastText: white,
    dark: '#E23333',
    main: '#F64444',
    light: '#FF6767',
  },
  text: {
    primary: '#1A2626',
    secondary: '#4E6363',
    disabled: '#93AAAA',
    hint: '#93AAAA',
  },
  background: {
    default: '#FCFEFE',
    paper: white,
  },
  icon: '#BFD5D5',
  divider: '#E9F2F2',
  action: {
    active: '#E0E0E0',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.06)',
    disabledBackground: 'rgba(0, 0, 0, 0.24)',
    opacity: 'rgba(0, 0, 0, 0.06)',
  },
  grey: {
    50: '#F9FDFD',
    100: '#F6FAFA',
    200: '#EFF7F7',
    300: '#E9F2F2',
    400: '#E2EEEE',
    500: '#C5D7D7',
    600: '#A4B9B9',
    700: '#93AAAA',
    800: '#7B9393',
    900: '#576C6C',
  },
};
export default palette;
