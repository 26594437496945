import React, {
  useState, useEffect, lazy,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
  Box, Typography, Link, Menu, MenuItem,
} from '@material-ui/core';
import { openSignUp } from 'actions/authDialogActions';
import { MY_ROTATIONS } from 'shared/queries/rotation/queries';
import RequestCustomSearchDialog from 'shared/components/RequestCustomSearchDialog';
import paymentDescriptions from 'apps/finances/constants/paymentDescriptions';
import CircularProgressCustom from 'shared/components/CircularProgressCustom';
import paths from 'paths';
import notFoundSearchStyles from './styles';

const Elements = lazy(() => import('@stripe/react-stripe-js').then(module => ({ default: module.Elements })));

const NotFoundSearch = () => {
  const classes = notFoundSearchStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [stripe, setStripe] = useState(null);

  const { data: signInCheckResult } = useSigninCheck();
  const isUserAuthenticated = signInCheckResult.signedIn;

  useEffect(() => {
    import('@stripe/stripe-js').then(({ loadStripe }) => {
      // Load Stripe only once when the component is first rendered to increase performance
      loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then(stripeInstance => {
        setStripe(stripeInstance);
      });
    });
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCustomSearchFirstPaymentStatus = payments => {
    if (payments) {
      const res = payments?.filter(item => {
        if (item.description.key === paymentDescriptions.customSearchPaymentOne) {
          return item;
        }
        return null;
      });
      return res[0]?.status?.key;
    }
    return null;
  };

  const { loading, data: { myRotations = [] } = {} } = useQuery(MY_ROTATIONS, {
    skip: !isUserAuthenticated,
  });

  const handleOpenSignUp = e => {
    e.preventDefault();
    dispatch(openSignUp());
  };

  const renderRotations = () => {
    if (myRotations.totalCount > 1) {
      return (
        <>
          <Link to="/" onClick={handleClick}>Create Request Custom search</Link>
          {stripe && (
            <Elements stripe={stripe}>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 0,
                  horizontal: 'center',
                }}
                getContentAnchorEl={null}
                PopoverClasses={{ paper: classes.popover }}
              >
                {myRotations.results.map(rotation => (
                  <RequestCustomSearchDialog
                    startDate={rotation.startDate}
                    endDate={rotation.endDate}
                    hours={rotation.hours}
                    specialty={rotation.specialty}
                    owners={rotation.owners}
                    rotationUID={rotation.uid}
                    rotationUuid={rotation.uuid}
                    key={rotation.uuid}
                    setProcessing={rotation.setProcessing}
                    errorAdd={rotation.errorAdd}
                    error={rotation.error}
                    setEmpty={rotation.setEmpty}
                    setErrorAdd={rotation.setErrorAdd}
                    setError={rotation.setError}
                    ButtonComponent={MenuItem}
                    buttonText={rotation.uid}
                    customSearchRequestStatus={rotation.customSearchRequestStatus}
                    firstPaymentStatus={getCustomSearchFirstPaymentStatus(rotation.payments || [])}
                  />
                ))}
              </Menu>
            </Elements>
          )}
        </>
      );
    } if (myRotations.totalCount > 0) {
      const rotation = myRotations.results[0];
      return (
        stripe && (
          <Elements stripe={stripe}>
            <RequestCustomSearchDialog
              startDate={rotation.startDate}
              endDate={rotation.endDate}
              hours={rotation.hours}
              specialty={rotation.specialty}
              owners={rotation.owners}
              rotationUID={rotation.uid}
              key={rotation.uuid}
              setProcessing={rotation.setProcessing}
              errorAdd={rotation.errorAdd}
              error={rotation.error}
              setEmpty={rotation.setEmpty}
              setErrorAdd={rotation.setErrorAdd}
              setError={rotation.setError}
              customSearchRequestStatus={rotation.customSearchRequestStatus}
              firstPaymentStatus={getCustomSearchFirstPaymentStatus(rotation.payments || [])}
              ButtonComponent={Link}
              rotationUuid={rotation.uuid}
              buttonText="Create Request Custom Search"
            />
          </Elements>
        )
      );
    }
    return (
      <Link component={RouterLink} to={paths.wizardPaths.welcome.reverse()}>
        Complete your wizard
      </Link>
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgressCustom />
      </Box>
    );
  }

  return (
    <Box className={classes.block}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="inherit"
        minHeight="300px"
      >
        <Box display="flex" justifyContent="center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/Images%2Fnot-foun-result.png?alt=media&token=b6a6675e-a8b8-448f-8f11-10720cc7f16e"
            alt="not-found"
            className={classes.image}
          />
        </Box>
        <Box display="flex" justifyContent="center" textAlign="center" mb={1}>
          <Typography variant="subtitle2" className={classes.textHint}>
            It looks like we don’t have any preceptors in our database that match your
            search criteria.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" textAlign="center">
          <Typography variant="subtitle2" className={classes.textHint}>
            Try changing your filters or take a look at preceptors in other locations.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="flex-end" mt={-2}>
        {isUserAuthenticated ? (
          <Box display="flex" justifyContent="center" textAlign="center">
            <Typography variant="subtitle2">
              If you would like us to do a Custom Search, please&nbsp;
              {renderRotations()}
              &nbsp;via Rotation, and we will see if we can find you what you need.
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" textAlign="center">
            <Typography variant="subtitle2">
              If you would like us to do a Custom Search, please
              {' '}
              <Link href="/" onClick={handleOpenSignUp}>Create your profile</Link>
              {' '}
              Request Custom search via Rotation, and we will see if we can find you what you need.
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center" textAlign="center">
          <Typography variant="subtitle2">
            We only accept students if we are confident we can place them.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" textAlign="center" mt={2}>
          <Typography variant="subtitle2" className={classes.textHint}>
            If you are in a Compact State, you might consider looking at other Compact States.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundSearch;
