import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const searchTagHiddenStyles = makeStyles(theme => createStyles({
  chips: {
    verticalAlign: 'top',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  chip: {
    '& .MuiChip-label': {
      maxWidth: '100%',
    },
  },
  tag: {
    ...typography.subtitle3,
    background: theme.palette.grey[200],
    padding: '1px 8px',
    color: theme.palette.grey[600],
    borderRadius: 8,
    display: 'inline-flex',
    verticalAlign: 'center',
  },
}));

export default searchTagHiddenStyles;
